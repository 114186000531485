<template>
  <div
    v-if="$can('read', 'LineUser')"
    style="height: inherit"
  >

    <!-- Filters -->
    <line-user-list-filters
      :collapsed-filter.sync="collapsedFilter"
      :name-filter.sync="nameFilter"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            sm="12"
            md="8"
          >
            <b-row>
              <b-col
                cols="12"
                sm="12"
                md="5"
                lg="4"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <!-- <b-form-checkbox
                  v-model="checkSelected"
                  class="custom-control-primary"
                  switch
                  @change="changeSelect"
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox> -->
                <label>{{ $t('Entries') }}</label>
                <v-select
                  v-model="perPage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block ml-50 mr-1"
                />
              </b-col>

              <!-- <b-col
                cols="12"
                sm="12"
                md="7"
                lg="8"
                class="d-flex align-items-left justify-content-start pl-0 ml-0 mb-1 mb-md-0"
              >
                <b-button
                  v-if="$can('create', 'LineUser')"
                  variant="primary"
                  @click="addLineUserForm"
                >
                  {{ `${$t('Add')} ${$t('Line User')}` }}
                </b-button>
              </b-col> -->
            </b-row>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            sm="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="`${$t('Search')}...`"
              />
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table
        ref="refLineUserListTable"
        :items="getLineUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        hover
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        selectable
        select-mode="multi"
        @row-selected="onRowSelected"
      >
        <!-- Column: DisplayName/UserId/Language/Id -->
        <template #cell(display_name)="data">
          <div style="margin-top: 0.3rem;">
            <b-list-group>
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
              >
                <span style="margin-right: 0.4rem;">
                  <b-avatar
                    :src="data.item.picture_url"
                    size="30"
                  />
                </span>
                <span style="font-size: 14px;">
                  {{ data.item.display_name }}
                </span>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div style="margin-top: 0.3rem;">
            <b-list-group>
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
              >
                <span style="margin-right: 0.6rem;">
                  <feather-icon
                    icon="TagIcon"
                    size="14"
                  />
                </span>
                <span style="font-size: 14px;">{{ data.item.user_id }}</span>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div style="margin-top: 0.3rem;">
            <b-list-group>
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
              >
                <span style="margin-right: 0.6rem;">
                  <feather-icon
                    icon="FlagIcon"
                    size="14"
                  />
                </span>
                <span style="font-size: 14px;">{{ data.item.language }}</span>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div style="margin-top: 0.3rem;">
            <b-list-group>
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
              >
                <span style="margin-right: 0.6rem;">
                  <feather-icon
                    icon="HashIcon"
                    size="14"
                  />
                </span>
                <span style="font-size: 14px;">
                  <b-badge
                    pill
                    :variant="(skin === 'dark')? `light-info` : `info` "
                    class="text-capitalize"
                  >
                    {{ data.item.id }}
                  </b-badge>
                </span>
              </b-list-group-item>
            </b-list-group>
          </div>
        </template>

        <!-- Column: Status/Follow/UnFollow/Block -->
        <template #cell(status)="data">
          <div style="margin-top: 0.3rem;">
            <b-list-group>
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
              >
                <span style="margin-right: 0.6rem;">
                  {{ $t('Follow') }} »
                </span>
                <span style="font-size: 14px;">
                  <b-badge
                    pill
                    :variant="(skin === 'dark')? `light-${isStatusVariant(data.item.is_follow)}` : `${isStatusVariant(data.item.is_follow)}` "
                    class="text-capitalize"
                  >
                    {{ $t(isStatusToText(data.item.is_follow)) }}
                  </b-badge>
                  &nbsp;
                  <b-badge
                    pill
                    :variant="(skin === 'dark')? `light-info` : `info` "
                    class="text-capitalize"
                  >
                    {{ data.item.date_follow_show }}
                  </b-badge>
                </span>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div style="margin-top: 0.3rem;">
            <b-list-group>
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
              >
                <span style="margin-right: 0.6rem;">
                  {{ $t('Unfollow') }} »
                </span>
                <span style="font-size: 14px;">
                  <b-badge
                    pill
                    :variant="(skin === 'dark')? `light-${isStatusVariant(data.item.is_unfollow)}` : `${isStatusVariant(data.item.is_unfollow)}` "
                    class="text-capitalize"
                  >
                    {{ $t(isStatusToText(data.item.is_unfollow)) }}
                  </b-badge>
                  &nbsp;
                  <b-badge
                    pill
                    :variant="(skin === 'dark')? `light-warning` : `warning` "
                    class="text-capitalize"
                  >
                    {{ data.item.date_unfollow_show }}
                  </b-badge>
                </span>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div style="margin-top: 0.3rem;">
            <b-list-group>
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
              >
                <span style="margin-right: 0.6rem;">
                  {{ $t('Block') }} »
                </span>
                <span style="font-size: 14px;">
                  <b-badge
                    pill
                    :variant="(skin === 'dark')? `light-${isStatusVariant(data.item.is_block)}` : `${isStatusVariant(data.item.is_block)}` "
                    class="text-capitalize"
                  >
                    {{ $t(isStatusToText(data.item.is_block)) }}
                  </b-badge>
                  &nbsp;
                  <b-badge
                    pill
                    :variant="(skin === 'dark')? `light-danger` : `danger` "
                    class="text-capitalize"
                  >
                    {{ data.item.date_block_show }}
                  </b-badge>
                </span>
              </b-list-group-item>
            </b-list-group>
          </div>
        </template>

        <!-- Column: RichMenu -->
        <template #cell(richmenu)="data">
          <div style="margin-top: 0.3rem;">
            <b-list-group>
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
              >
                <span style="margin-right: 0.6rem;">
                  {{ $t('Type') }} »
                </span>
                <span style="font-size: 14px;">
                  <b-badge
                    pill
                    :variant="(skin === 'dark')? `light-info` : `info` "
                    class="text-capitalize"
                  >
                    {{ $t(textFirstUpper(data.item.link_richmenu_type)) }}
                  </b-badge>
                </span>
              </b-list-group-item>
            </b-list-group>
          </div>
          <div style="margin-top: 0.3rem;">
            <b-list-group>
              <b-list-group-item
                style="padding: 0.2rem 0.6rem 0.2rem 0.6rem;"
              >
                <span style="margin-right: 0.6rem;">
                  {{ $t('Date') }} »
                </span>
                <span style="font-size: 14px;">
                  <b-badge
                    pill
                    :variant="(skin === 'dark')? `light-secondary` : `secondary` "
                    class="text-capitalize"
                  >
                    {{ data.item.link_richmenu_date_show }}
                  </b-badge>
                </span>
              </b-list-group-item>
            </b-list-group>
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">
            <feather-icon
              v-if="$can('write', 'LineUser')"
              :id="`line-user-row-${data.item.id}-edit-icon`"
              icon="EditIcon"
              class="cursor-pointer mr-1"
              size="16"
              @click="editLineUserForm(data)"
            />
            <b-tooltip
              v-if="$can('write', 'LineUser')"
              :title="`${$t('Edit')} ${$t('Line User')}`"
              :target="`line-user-row-${data.item.id}-edit-icon`"
              :delay="{ show: 2500, hide: 50 }"
              placement="left"
            />

            <feather-icon
              v-if="$can('read', 'LineUser')"
              :id="`line-user-row-${data.item.id}-view-icon`"
              icon="EyeIcon"
              size="16"
              class="cursor-pointer mr-1"
              @click="viewLineUserForm(data)"
            />
            <b-tooltip
              v-if="$can('read', 'LineUser')"
              :title="`${$t('View')} ${$t('Line User')}`"
              :target="`line-user-row-${data.item.id}-view-icon`"
              :delay="{ show: 2500, hide: 50 }"
              placement="left"
            />

            <feather-icon
              v-if="$can('delete', 'LineUser')"
              :id="`line-user-row-${data.item.id}-delete-icon`"
              icon="TrashIcon"
              variant="light-success"
              size="16"
              class="cursor-pointer mr-1"
              @click="deleteLineUserForm(data)"
            />
            <b-tooltip
              v-if="$can('delete', 'LineUser')"
              :title="`${$t('Delete')} ${$t('Line User')}`"
              :target="`line-user-row-${data.item.id}-delete-icon`"
              :delay="{ show: 2500, hide: 50 }"
              placement="right"
            />

          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              {{ $t('Showing {from} to {to} of {of} entries',
                    {from: dataMeta.from, to: dataMeta.to, of: dataMeta.of })
              }}
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalLineUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <line-user-form
      v-model="shallShowLineUserFormModal"
      :line-user-data="lineUserData"
      :type-line-user-form="typeLineUserForm"
      @line-user-form-update="updateLineUserForm"
      @discard-line-user-form="discardLineUserForm"
    />

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput,
  BTable,
  // BButton,
  BBadge, BPagination, BTooltip,
  // BFormCheckbox,
  BListGroup, BListGroupItem,
  BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ref,
  onUnmounted,
} from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import { $themeConfig } from '@themeConfig'
import useLineUserList from './useLineUserList'
import lineUserStoreModule from './lineUserStoreModule'
import LineUserForm from './LineUserForm.vue'
import LineUserListFilters from './LineUserListFilters.vue'

export default {
  components: {
    LineUserListFilters,
    LineUserForm,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BPagination,
    BTooltip,
    // BFormCheckbox,
    BListGroup,
    BListGroupItem,
    // BButton,
    BAvatar,

    vSelect,
    FeatherIcon,
  },
  data() {
    return {
      lineUserData: {},
      dialog: false,
      skin: store.state.appConfig.layout.skin,
      selected: [],
      checkSelected: false,
      collapsedFilter: true,
    }
  },
  Computed: {
    checkSelected() {
      return !this.checkSelected
    },
  },
  mounted() {
    this.getDefaultLineUser()
    this.locale = this.$i18n.locale
  },
  methods: {
    getDefaultLineUser() {
      store
        .dispatch('line-user-store/getDefaultLineUser', { language: this.$i18n.locale })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.lineUserData = response.data
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    onRowSelected(items) {
      this.selected = items
      if (this.selected.length > 0) {
        this.checkSelected = true
      } else {
        this.checkSelected = false
      }
    },
    changeSelect() {
      if (this.checkSelected === true) {
        this.$refs.refLineUserListTable.selectAllRows()
      } else {
        this.$refs.refLineUserListTable.clearSelected()
      }
    },
    addLineUserForm() {
      this.lineUserData = {
        display_name: '',
      }
      this.typeLineUserForm = 'Add'
      this.shallShowLineUserFormModal = true
    },
    editLineUserForm(data) {
      store
        .dispatch('line-user-store/getLineUser', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.lineUserData = response.data
          this.typeLineUserForm = 'Edit'
          this.shallShowLineUserFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    viewLineUserForm(data) {
      store
        .dispatch('line-user-store/getLineUser', { language: this.$i18n.locale, id: data.item.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.lineUserData = response.data
          this.typeLineUserForm = 'View'
          this.shallShowLineUserFormModal = true
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    deleteLineUserForm(data) {
      this.$swal({
        title: this.$i18n.t('Are you sure?'),
        text: this.$i18n.t("You won't be able to revert this"),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$i18n.t('Yes, delete it'),
        cancelButtonText: this.$i18n.t('Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value === true) {
          store.dispatch('line-user-store/deleteLineUser', data.item.id)
            .then(() => {
              const okMsg = this.$i18n.t('Process on successfully')
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: okMsg,
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              },
              {
                position: 'bottom-right',
              })
              this.$swal({
                icon: 'success',
                title: this.$i18n.t('Deleted'),
                text: this.$i18n.t('Your data has been deleted'),
                timer: 3000,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.refetchData()
            })
            .catch(error => {
              const { response, message } = error
              if (response) {
                let errorMsg = this.$i18n.t(response?.data.error)
                if (errorMsg === '') {
                  errorMsg = this.$i18n.t(response?.data.message)
                }
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: errorMsg,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              } else if (message) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error?.message,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'bottom-right',
                })
              }
            })
        }
      })
    },
    updateLineUserForm() {
      this.refetchData()
    },
    discardLineUserForm() {
      this.refetchData()
    },
  },
  setup() {
    const shallShowLineUserFormModal = ref(false)
    const typeLineUserForm = ref('')

    const STORE_MODULE_NAME = 'line-user-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, lineUserStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      getLineUsers,
      tableColumns,
      perPage,
      currentPage,
      totalLineUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refLineUserListTable,
      refetchData,

      // UI
      isStatusVariant,
      isStatusToText,
      textFirstUpper,

      nameFilter,
    } = useLineUserList()

    const filterList = JSON.parse(localStorage.getItem(`${$themeConfig.app.session}-filter-line-user-list`))
    if (filterList !== null) {
      searchQuery.value = (filterList.searchQuery !== null) ? filterList.searchQuery : ''
      perPage.value = (filterList.perPage !== null) ? filterList.perPage : ''
      currentPage.value = (filterList.page !== null) ? filterList.page : ''
      sortBy.value = (filterList.sortBy !== null) ? filterList.sortBy : ''
      isSortDirDesc.value = (filterList.sortDesc !== null) ? filterList.sortDesc : ''
      nameFilter.value = (filterList.nameFilter !== null) ? filterList.nameFilter : ''
    }

    return {
      shallShowLineUserFormModal,
      typeLineUserForm,

      getLineUsers,
      tableColumns,
      perPage,
      currentPage,
      totalLineUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      locale,
      refLineUserListTable,
      refetchData,

      // UI
      isStatusVariant,
      isStatusToText,
      textFirstUpper,

      nameFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.line-user-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
    .swal2-popup {
      background-color: $theme-dark-body-bg !important;
    }
  }
  .table.b-table > tbody .b-table-row-selected.table-active td {
    background-color: $theme-dark-table-active-bg;
  }
  .table.b-table.table-hover > tbody > tr.table-active:hover td,
  .table.b-table.table-hover > tbody > tr.table-active:hover th {
    background-image: linear-gradient($theme-dark-table-active-hover-bg, $theme-dark-table-active-hover-bg);
  }
}
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
</style>
